import React from "react";
import Ratings from "./Ratings";
import FAQs from "./FAQs";
import "../../css/landing/landing.css";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

export default function Body() {
  const signUp = () => {
    window.location.href = "https://student.excelmind.org/create-account";
  };

  return (
    <div className="body-container">
      <div className="exams">
        <h4>
          Practise 50+ of International, Professional and Local examinations
          Simulators
        </h4>

        <img src="./images/logo-line.svg" />
        <img src="./images/logo-line-mobile.svg" className="logo-line-mobile" />
      </div>

      <div className="we-offer" id="about">
        <h4 className="head">
          {" "}
          ExcelMind, your go-to destination for exam preparation
        </h4>

        <div
          data-aos="zoom-out-up"
          data-aos-duration="2000"
          className="offer-features"
        >
          <div className="left">
            <img src="./images/offline.svg" />
            <h4>Offline Practise</h4>
            <p>
              Get access to practice past questions on the go. Without internet
              access you can still keep learning. You only need internet to
              login
            </p>
          </div>
          <div className="center">
            <img src="./images/track-progress.svg" />
            <h4>Track Progress</h4>
            <p>
              Keep track on your progress with our easy-to-navigate system. Our
              simplified interface helps you know how far you still need to go
              just at a glance.
            </p>
          </div>
          <div className="right">
            <img src="./images/one-time-payment.svg" />
            <h4>Affordable Fee</h4>
            <p>
              Because we are interested in your success as much as you are, we
              offer our services at competitive rates so you can focus on
              learning and succeeding.
            </p>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="sec-1">
          <div data-aos="fade-right" data-aos-duration="2000">
            <h4>Our simulator covers a wide range of exams.</h4>
            <p>
              We understand that different exams have different requirements. so
              we ensure that our simulator is tailored to meet the specific
              needs of each exam
            </p>{" "}
            <br />
            <button onClick={signUp} className="get-started">
              Get Started
            </button>
          </div>

          <div data-aos="fade-left" data-aos-duration="2000">
            <img src="./images/pic-1.svg" />
          </div>
        </div>

        <div className="sec-2">
          <div data-aos="fade-right" data-aos-duration="2000">
            <img src="./images/pic-2.svg" />
          </div>

          <div data-aos="fade-left" data-aos-duration="2000">
            <h4>Get Use to The Exam Format And Structure</h4>
            <p>
              With our simulator, you can practice as many times as you want,
              answering questions that are similar to the ones you'll find in
              the actual exam.
            </p>{" "}
            <br />
            <button onClick={signUp} className="get-started get-started2">
              Get Started
            </button>
          </div>
        </div>
      </div>

      {/* <div className="exams-container">
        <div className="exam-header">
          <h4>
            ExcelMind provides past questions from some of the <br /> most
            popular exams in Nigeria and Internationally.
          </h4>
        </div>

        <div className="grid">
          <Exams
            image="./images/jamb-landing.svg"
            exam="JAMB"
            info="Joint Admissions and Matriculation Board"
          />
          <Exams
            image="./images/waec.svg"
            exam="WAEC"
            info="West African Examinations Council"
          />
          <Exams
            image="./images/neco.svg"
            exam="NECO"
            info="National Examinations Council"
          />
          <Exams
            image="./images/gce.svg"
            exam="GCE"
            info="General Certificate of Education:"
          />
          <Exams
            image="./images/toefl.svg"
            exam="TOEFL"
            info="Test of English as a Foreign Language"
          />
          <Exams
            image="./images/sat.svg"
            exam="SAT"
            info="the Scholastic Assessment Test"
          />
          <Exams
            image="./images/gre.svg"
            exam="GRE"
            info="The graduate record examination (GRE)"
          />
          <Exams
            image="./images/gmat.svg"
            exam="GMAT"
            info="The Graduate Management Admission Test "
          />
        </div>
      </div> */}

      <div className="download-excelmind">
        <div className="left-side-download">
          <h2>
            Get the ExcelMind <br /> Mobile App
          </h2>

          <p>
            Join thousands of satisfied users who have <br />
            achieved their goals with ExcelMind.
          </p>
          <p className="mobile-download">
            Join thousands of satisfied users who have achieved their goals with
            ExcelMind.
          </p>

          <div className="download-botton">
            <img src="./images/playstore.svg" className="playstore" />
            <img src="./images/appstore.svg" className="appstore" />
          </div>
        </div>

        <div className="right-side-download">
          <img className="download-img" src="./images/download-phone.svg" />
        </div>
      </div>

      <div className="ambassador-x">
        <div className="ambassador" data-aos="fade-up" data-aos-duration="2000">
          <h5> Become our Ambassador</h5>

          <p>
            Refer your friends, classmates, or colleagues to ExcelMind and earn
            a generous amount monthly.
          </p>

          <img src="./images/ambssador-image.svg" alt="" />
        </div>

        <div className="advantage" data-aos="fade-up" data-aos-duration="2000">
          <h5> Seize the ExcelMind Advantage!</h5>

          <p>
            Study like a pro, practice strategically and boost your exam
            performance.
          </p>

          <img src="./images/advantage.png" alt="" />
        </div>
      </div>

      <div className="ratings">
        <h4>What People Says About us</h4>
        <div className="star-col">
          <Ratings
            input="“I am so glad I came across Excel Mind at the time I did. It made preparing for my TOEFL exams
            less stressful and I could study more effectively because I had past questions to guide me.”"
            userprofile="./images/user-2.svg"
            username="Michael Mololuwa"
          />
          <Ratings
            input="“After writing and failing Jamb 3 times, I was ready to give up on ever passing the exams. Excel
            mind changed my story from failure to success and I know it can do the same for anyone. I
            would recommend it to any student 100%.”"
            userprofile="./images/user-3.svg"
            username="Kehinde Adegbesan"
          />
          <Ratings
            input="“My experience writing exams has always been terrible. After going from failure to failure, 
            I eventually stumbled on ExcelMind and that was the turning point for me. Now I enter exam halls 
            confident and certain that I will do well because I am well prepared”"
            userprofile="./images/user-1.svg"
            username="Tewogbola Temitope"
          />
        </div>
      </div>

      <div className="how-app-works">
        <h1>How Our App Works</h1>

        <div className="content">
          <div className="left">
            <div className="point">
              <div>
                <img src="./images/one.svg" alt="" />
              </div>

              <div>
                <h3> Create an account</h3>
                <p>
                  {" "}
                  Sign up for an account with your name, email and phone number.
                </p>
              </div>
            </div>
            <div className="point">
              <div>
                <img src="./images/two.svg" alt="" />
              </div>

              <div>
                <h3> Select Exam Category </h3>
                <p>
                  {" "}
                  Choose from a wide range of exams, from international to local
                  and professional tests.
                </p>
              </div>
            </div>
            <div className="point">
              <div>
                <img src="./images/three.svg" alt="" />
              </div>

              <div>
                <h3> Start Learning </h3>
                <p>
                  {" "}
                  Dive into our comprehensive question simulator and begin your
                  path to excellence."
                </p>
              </div>
            </div>
          </div>

          <div className="right">
            <img src="./images/how-it-works.svg" alt="" />
          </div>
        </div>
      </div>

      {/* <Solutions /> */}
      {/* <Contact /> */}

      {/* <div className="blogs" id="blog">
        <h4 className="blog-head">
          {" "}
          ExcelMind brings you up to date news about school <br />
          in and around the world
        </h4>

        <div className="blog">
          <div className="blog-1">
            <img src="./images/blog-1.svg" />
            <h4>List of Schools Whose POST UTME Forms Are Out</h4>
            <p>
              Are you looking for a list of schools whose post-UTME froms are
              presently avaialabe{" "}
            </p>
            <a
              href="https://www.myschoolgist.com/ng/post-utme-updates/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button>Read More</button>
            </a>
          </div>
          <div className="blog-2">
            <img src="./images/blog-2.svg" />
            <h4>Latest Syllabus for All Subjects (SSCE, GCE) 2022 </h4>
            <p>
              WAEC Syllabus for school candidate (May/June) and Private
              candidates (GCE) is out{" "}
            </p>
            <a
              href="https://www.myschoolgist.com/ng/waec-syllabus-by-subject-2013/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button>Read More</button>
            </a>
          </div>
          <div className="blog-3">
            <img src="./images/blog-3.svg" />
            <h4>How to Upload O’Level Result on JAMB Portal / Profile 2022 </h4>
            <p>
              It is required that candidates upload their WAEC and NECO results
              onto the JAMB website.
            </p>
            <a
              href="https://www.myschoolgist.com/ng/pload-olevel-result-jamb-portal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button>Read More</button>
            </a>
          </div>
        </div>
      </div> */}

      {/* <Faq /> */}
      <FAQs />
    </div>
  );
}
