import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingHome from "./pages/LandingPage/Home";
import LandingPage from "./pages/LandingPage/Landing";

import Policy from "./pages/LandingPage/Policy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}>
          <Route path="" element={<LandingHome />} />
          <Route path="privacy-policy" element={<Policy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
